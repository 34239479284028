$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();

    function fixedStage() {

    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(this).toggleClass("active");
        $(".js-navmobile, body").toggleClass("active");
        $(".js-header").toggleClass("navOpen");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * carouselTag
	// *
	// *
	var $carouselTag = $(".js-carouseltag");

	if ($carouselTag.length){
		$carouselTag.each(function(){
            // fix for slick js infinite scroll bug - slidesToShow has to be half of available slide
            var lengthTag = $(this).find(".js-carouseltag-tag").length;

			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: false,
				autoplay: true,
                autoplaySpeed: 1000,
                centerMode: true,
				speed: 700,
                variableWidth: true,
                slidesToShow: lengthTag / 2,
                waitForAnimate: true,
                cssEase: 'ease',
			});
		});
	}


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cardFlip
    // *
    // *
    // toggle back of card
    $(".js-cardflip").click(function(){
        $(".js-cardflip").not($(this)).removeClass("backface");
        $(this).toggleClass("backface");
    });

    // $(".js-cardflip-close").click(function(){
    //     $(this).parents(".js-cardflip").removeClass("backface");
    // });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * match
    // *
    // *
    // toggle info text
    $(".js-match-info").click(function(){
        $(this).toggleClass("active").parents(".js-match").find(".match__front,.match__back").slideToggle();
    });

    $(".js-match-button").click(function(){
        // toggle checked steps
        $(this).parents(".js-match").toggleClass("checked");

        var elementsLength = $(this).parents(".js-match-wrapper").find(".js-match").length,
            activeElementsLength = $(this).parents(".js-match-wrapper").find(".js-match.checked").length;

        // if all elements are checked, activate submit button
        if (elementsLength === activeElementsLength) {
            $(this).parents(".js-match-wrapper").find(".js-match-submit").addClass("active");
        } else {
            $(this).parents(".js-match-wrapper").find(".js-match-submit").removeClass("active");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * sliderCard
	// *
	// *
	var $sliderCard = $(".js-slidercard");

	if ($sliderCard.length){
		$sliderCard.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: false,
				arrows: true,
                prevArrow: $(this).parents(".js-slidercard-wrapper").find(".js-slidercard-prev"),
                nextArrow: $(this).parents(".js-slidercard-wrapper").find(".js-slidercard-next"),
				autoplay: false,
				speed: 1000,
                variableWidth: true,
                waitForAnimate: true,
                cssEase: 'ease',
			});
		});
	}


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mapLocations
    // *
    // *
    var $mapPin = $(".js-maplocations-pin");

    // position pins
    $mapPin.each(function() {
        $(this).css("left", $(this).data("position-x") + '%');
        $(this).css("top", $(this).data("position-y") + '%');
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * sliderQUote
	// *
	// *
	var $sliderQuote = $(".js-sliderquote");

	if ($sliderQuote.length){
		$sliderQuote.each(function(){
            var total = $sliderQuote.find(".sliderQuote__quote").length.toString().padStart(2,0);

			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                prevArrow: $(this).parents(".js-sliderquote-wrapper").find(".js-sliderquote-prev"),
                nextArrow: $(this).parents(".js-sliderquote-wrapper").find(".js-sliderquote-next"),
				autoplay: false,
				speed: 1000,
                adaptiveHeight: true,
                waitForAnimate: true,
                cssEase: 'ease',
			});

            // set total
            $(this).parents(".js-sliderquote-wrapper").find(".js-sliderquote-total").html(total);

            // set current count
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                // var nextSlideFormatted = ;
                // console.log(nextSlideFormatted);

                $(this).parents(".js-sliderquote-wrapper").find(".js-sliderquote-current").html((nextSlide + 1).toString().padStart(2,0));
            });
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * accordion
	// *
	// *
    $(".js-accordion-button").click(function() {
        $(this).toggleClass("active").siblings(".js-accordion-content").slideToggle();
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * intro scroll
	// *
	// *
    $(".js-intro-scroll").click(function() {
        $('html, body').animate({
            scrollTop: window.innerHeight + 'px'
           }, 0)
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slideText
	// *
	// *
    $(".js-slidetext").each(function() {
        var delay = $(this).data("animation-delay");
        $(this).css("animation-delay", delay + "s").addClass("animate");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * star Rating
	// *
	// *
    $(".js-star").on("mouseenter", function() {
        $(".js-star").removeClass("hover");
        $(this).addClass("hover").prevAll().addClass("hover");
    }).on("mouseleave", function() {
        $(".js-star").removeClass("hover");
    });

    $(".js-star").click(function() {
        $(".js-star").removeClass("hover rated");
        $(this).toggleClass("rated").prevAll().toggleClass("rated");
        var rating = $(this).data("rating");
        $(".js-rating-stars").val(rating);
    });

    $(document).on('submit', '.js-rating-form', function(event){
        event.preventDefault();

        var stars = $(".js-rating-stars").val(),
            message = $(".js-rating-message").val(),
            videoId = $('[data-video-id]').data("video-id"),
            $form = $(this);

        if (stars <= 0) return;

        // submit form
        $.ajax({
            url : "/assets/components/site/connector.php?action=ratevideo",
            type: 'GET',
            data: { stars : stars, message : message, videoId : videoId }
        }).done(function(data){
            if(data.status == 'success'){
                // reset form
                $form.trigger('reset');
                $(".js-star").removeClass("hover rated");

                // close popup
                $(".js-modalrating-button").trigger('click');

                // show rating
                $(".js-rating-result").removeClass("star__wrapper--rating-1 star__wrapper--rating-2 star__wrapper--rating-3 star__wrapper--rating-4 star__wrapper--rating-5 star__wrapper--rating-6 star__wrapper--rating-7 star__wrapper--rating-8 star__wrapper--rating-9 star__wrapper--rating-10");
                $(".js-rating-result").addClass("star__wrapper--rating-" + data.rating);
                $(".js-modalrating-button").addClass("locked");
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });


    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter module videos
    // *
    // *
    if ($(".js-filter-elements").length) {
        var mixer = mixitup('.js-filter-elements', {
            multifilter: {
                enable: true // enable the multifilter extension for the mixer
            },
            selectors: {
                target: '.js-filter-element'
            },
            load: {
                filter: '.all'
            }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * modalRating
	// *
	// *
    $(".js-modalrating-button").click(function() {
        $("body").toggleClass("freeze");
        $(".js-modalrating").toggleClass("active");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * module progress
	// *
	// *
    if ($(".js-progress").length) {
        var courseProgress = $(".js-filter-completed").length,
            courseTotal = $(".js-course").length;

        $(".js-progress").html(courseProgress);
        $(".js-progress-total").html(courseTotal);

        $(".js-progress-bar").css("width", courseProgress / courseTotal * 100 + "%");

    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * js header action button
	// *
	// *
    $(".js-headeraction-button").click(function() {
        $(this).parents(".js-headeraction").toggleClass("active");
        $(".js-headersearch").toggleClass("actionOpen");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * js header search button
	// *
	// *
    $(".js-headersearch").click(function() {
        $(this).addClass("active").find("input").focus();
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * track Progress of video
	// *
	// *
    if (typeof player !== 'undefined') {
        player.on('playing', (event) => {
            videoTrackProgress(0);
        });

        player.on('ended', (event) => {
            videoTrackProgress(1);
        });
    }

    function videoTrackProgress(status) {
        $.ajax({
            url : "/assets/components/site/connector.php?action=videoprogress",
            type: 'GET',
            data: { videoId : $('[data-video-id]').data("video-id"), status : status }
        }).done(function(data){
            if(data.status == 'success'){
                // do nothing
            }else if(data.status == 'error'){
                // handle Error
                console.log('Error:' + data);
            }
        });
    }




});


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * stage
	// *
	// *
    $(window).on("load scroll",function() {

        // change position of stage content from fixed to relative when door is scrolled
        if($(".js-stage").length) {
            var elementBottom = $(".js-stage-door").offset().top + $(".js-stage-door").outerHeight(),
                viewPortStart = $(window).scrollTop();

            if (viewPortStart >= elementBottom) {
                $(".js-stage").addClass("relative");
            } else {
                $(".js-stage").removeClass("relative");
            }
        }


        // * * * * * * * * * * * * * * * * * * * * * * * * *
        // * remove active classes from header
        // *
        // *
        $(".js-headeraction").removeClass("active");
        $(".js-headersearch").removeClass("actionOpen");
        $(".js-headersearch").removeClass("active");
    });

